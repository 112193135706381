import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import { useAnimatedInView } from './AboutPage.shared';

import css from './AboutPage.module.css';

export const SectionProblem = props => {
  const { sectionId, isMobileLayout } = props;

  const { ref, AnimatedDiv } = useAnimatedInView({
    animate: !isMobileLayout,
    threshold: 0.7,
    triggerOnce: true,
  });

  return (
    <div id={sectionId} className={css.sectionProblem}>
      <div className={css.animatedContainer} ref={ref}>
        <AnimatedDiv>
          <Heading className={css.title} as="h2">
            <FormattedMessage id="AboutPage.SectionProblem.title" />
          </Heading>
        </AnimatedDiv>

        <AnimatedDiv delay={0.5}>
          <p className={css.text}>
            <FormattedMessage id="AboutPage.SectionProblem.textLine1" />
          </p>
        </AnimatedDiv>

        <br className={css.sectionProblemTextSpacing} />

        <AnimatedDiv delay={1}>
          <p className={css.text}>
            <FormattedMessage id="AboutPage.SectionProblem.textLine2" />
          </p>
        </AnimatedDiv>

        <br className={css.sectionProblemTextSpacing} />

        <AnimatedDiv delay={1.5}>
          <p className={css.text}>
            <FormattedMessage id="AboutPage.SectionProblem.textLine3" />
          </p>
        </AnimatedDiv>
      </div>
    </div>
  );
};

export default SectionProblem;
