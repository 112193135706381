import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/uiHelpers';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { useConfiguration } from '../../context/configurationContext';
import { Page, LayoutSingleColumn, Footer } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import AsideNav, { ABOUT, PROBLEM, SOLUTION, HOW_IT_WORKS, REVIEWS, FAQS, HOME } from './AsideNav';
import SectionAbout from './SectionAbout';
import SectionProblem from './SectionProblem';
import SectionSolution from './SectionSolution';
import SectionHowItWorks from './SectionHowItWorks';
import SectionReviews from './SectionReviews';
import SectionFaqs from './SectionFaqs';
import css from './AboutPage.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

/**
 * Returns an array of section components.
 *
 * @param {Object} componentProps - Additional props to pass to each section component.
 * @returns {Array<React.Component>} An array of section components.
 */
const getSectionComponents = componentProps => [
  <SectionAbout key={ABOUT} sectionId={ABOUT} {...componentProps} />,
  <SectionProblem key={PROBLEM} sectionId={PROBLEM} {...componentProps} />,
  <SectionSolution key={SOLUTION} sectionId={SOLUTION} {...componentProps} />,
  <SectionHowItWorks key={HOW_IT_WORKS} sectionId={HOW_IT_WORKS} {...componentProps} />,
  <SectionReviews key={REVIEWS} sectionId={REVIEWS} {...componentProps} />,
  <SectionFaqs key={FAQS} sectionId={FAQS} {...componentProps} />,
];

export const getSectionLandingPageComponents = componentProps => [
  <SectionProblem key={PROBLEM} sectionId={PROBLEM} {...componentProps} />,
  <SectionSolution key={SOLUTION} sectionId={SOLUTION} {...componentProps} />,
  <SectionHowItWorks key={HOW_IT_WORKS} sectionId={HOW_IT_WORKS} {...componentProps} isHomePage={true} />,
  <SectionReviews key={REVIEWS} sectionId={REVIEWS} {...componentProps} isHomePage={true} />,
  <SectionFaqs key={FAQS} sectionId={FAQS} {...componentProps} isHomePage={true}/>,
];

export const AboutPageComponent = props => {
  const { intl, viewport, scrollingDisabled } = props;

  const config = useConfiguration();

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'AboutPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({ id: 'AboutPage.schemaDescription' });
  const NAV = [ABOUT, PROBLEM, SOLUTION, HOW_IT_WORKS, REVIEWS, FAQS];
  const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;

  const sectionComponentProps = { marketplaceName, isMobileLayout };
  const sectionComponents = getSectionComponents(sectionComponentProps);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbarClassName={css.topbar}
        topbar={<TopbarContainer currentPage="AboutPage" />}
        footer={<Footer currentPage="AboutPage" />}
      >
        <div className={css.content}>
          <div className={css.asideContent}>
            <AsideNav intl={intl} isMobileLayout={isMobileLayout} NAV={NAV}/>
          </div>
          <div className={css.sections}>{sectionComponents}</div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, number, object, shape } = PropTypes;

AboutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AboutPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(AboutPageComponent);

export default AboutPage;
