import PropTypes, { number, shape } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Footer, LayoutSingleColumn, Page } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { ensureCurrentUser } from '../../util/data';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { loadProfileData } from './LandingPage.helpers';

import { withViewport } from '../../util/uiHelpers';
import { getSectionLandingPageComponents } from '../AboutPage/AboutPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import HeroBoxes from './HeroBoxes/HeroBoxes';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    isAuthenticated,
    currentUser,
    talentProfileData,
    companyProfileData,
    isEmailTaken,
    talentPage,
    companyPage,
    signupError,
    viewport,
  } = props;
  const config = useConfiguration();
  const user = ensureCurrentUser(currentUser);
  const profileData = loadProfileData(talentProfileData, companyProfileData);
  const [selectedRegisterType, setSelectedRegisterType] = useState('candidate');

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { marketplaceName });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });

  const {
    createListingDraftError = null,
    publishListingError = null,
    updateListingError = null,
  } = talentPage;

  const errors = {
    createListingDraftError,
    publishListingError,
    updateListingError,
    signupError,
  };

  const currentTalentProfile = profileData.talent;
  const currentCompanyProfile = profileData.company;

  const initialValues =
    currentTalentProfile && currentTalentProfile != null
      ? {
          email: currentTalentProfile.email,
          registerType: currentTalentProfile.registerType
            ? currentTalentProfile.registerType
            : 'candidate',
        }
      : currentCompanyProfile && currentCompanyProfile != null
      ? {
          email: currentCompanyProfile.email,
          registerType: currentCompanyProfile.registerType,
        }
      : {
          registerType: 'candidate',
        };

  const MAX_MOBILE_SCREEN_WIDTH = 768;
  const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;

  const sectionComponentProps = { marketplaceName, isMobileLayout };
  const sectionComponents = getSectionLandingPageComponents(sectionComponentProps);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbarClassName={css.topbar}
        topbar={<TopbarContainer currentPage="LandingPage" />}
        footer={<Footer className={css.footer} />}
      >
        <div className={css.fullWidthContainer}>
          <div className={css.content}>
            {/* <HeadingIcon className={css.heading} /> */}
            {/* <p className={css.headingText}>
              <FormattedMessage id="LandingPage.headingText" />
            </p> */}
            <HeroBoxes
              className={css.heroBoxes}
              intl={intl}
              isAuthenticated={isAuthenticated}
              currentUser={user}
              talentProfileData={profileData.talent}
              companyProfileData={profileData.company}
              initialValues={initialValues}
              isEmailTaken={isEmailTaken}
              errors={errors}
              sectionComponents={sectionComponents}
              isMobileLayout={isMobileLayout}
              sectionComponentProps={sectionComponentProps}
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // user
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,

  // profileData
  talentProfileData: object,
  companyProfileData: object,

  // from withRouter
  // history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
  isEmailTaken: bool.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const talentPage = state.CreateTalentPage;
  const companyPage = state.CreateCompanyPage;
  const { isEmailTaken } = state.auth;
  return {
    scrollingDisabled: isScrollingDisabled(state),

    // user
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.user.currentUser,

    // profileData
    talentProfileData: state.CreateTalentPage.profileData,
    companyProfileData: state.CreateCompanyPage.profileData,
    isEmailTaken,
    talentPage,
    companyPage,
    signupError: state.auth.signupError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
