import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import { faqs } from './AboutPage.shared';
import css from './AboutPage.module.css';

const MinusIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" {...props}>
    <path d="m.354.646 18 18M18.354 1.354l-18 18" />
  </svg>
);

const PlusIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" {...props}>
    <path d="M13.5 0v25.456M25.727 13.228H.272" />
  </svg>
);

const FaqItem = props => {
  const { faq, isActive, onClick } = props;
  const { question, answer } = faq;

  const toggleActive = () => {
    onClick(faq.id);
  };

  const faqIcon = isActive ? (
    <MinusIcon className={css.faqIcon} />
  ) : (
    <PlusIcon className={css.faqIcon} />
  );
  const faqText = isActive ? <p className={css.faqText}>{answer}</p> : null;

  return (
    <div className={css.faq} onClick={toggleActive}>
      <div className={css.faqHeadingWrapper}>
        <Heading className={css.faqHeading} as="h4">
          {question}
        </Heading>
        {faqIcon}
      </div>
      {faqText}
    </div>
  );
};

const SectionFaqs = props => {
  const { sectionId, isHomePage } = props;

  // State to manage the id of the active FAQ item
  const [activeFaqId, setActiveFaqId] = useState(faqs[0].id);

  // Handles the click event on a FAQ item.
  const handleFaqClick = id => {
    setActiveFaqId(activeFaqId === id ? null : id);
  };

  return (
    <div id={sectionId} className={!isHomePage ? css.sectionFaqs : css.sectionLandingPageFaqs}>
      <Heading className={css.faqsTitle} as="h2">
        <FormattedMessage id="AboutPage.SectionFaqs.title" />
      </Heading>
      <div className={css.faqs}>
        {faqs.map(faq => (
          <FaqItem
            key={faq.id}
            faq={faq}
            isActive={activeFaqId === faq.id}
            onClick={handleFaqClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionFaqs;
